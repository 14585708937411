import CustomCheckBox from '@/components/customCheckBox';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { FormControlLabel, Grid, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import { isEmpty, keyBy, omit, pick, sortBy, toLower, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import { CommerceDuplicate, CommercesWrite, CommerceWrite } from '../data/commerce/commerce.graphql';
import { AgreementsRead } from '../data/management/agreement.graphql';
import { LineItemsRead } from '../data/management/lineItem.graphql';
import idPick from '../helpers/idPick';
import { safeFormatInTimeZone } from '../helpers/safeFormat';
import { commercePropertiesToOmit } from '../pages/dashboard/commerce/invoices/actions/invoiceUtils';
import { getClientForMerchant } from '../pages/tender/[id]/drawersActions/clientHelpers';
import {
	Client,
	MutationCommerceDuplicateArgs,
	MutationCommercesWriteArgs,
	MutationCommerceWriteArgs,
	Order,
	QueryAgreementsReadArgs,
	QueryLineItemsReadArgs,
} from '../types/schema';
import postCloverMeteredBilling from '../utils/api/postCloverMeteredBilling';

export default function MergeOptions( {
	type,
	clearRows,
	commerces,
	selectedCommerces,
}: {
	type: string,
	clearRows: () => void,
	commerces: string[],
	selectedCommerces: Order[]
} ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const router = useRouter();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	
	const [ mergeType, setMergeType ] = useState( 'order' );
	const [ addDescription, setAddDescription ] = useState( true );
	const commerceType = toLower( type );
	if ( isEmpty( selectedCommerces ) ) return null;
	
	return (
		<ResponsiveModalContainer
			title={`Merge ${upperFirst( toLower( selectedCommerces?.[ 0 ]?.type ) )}s`}
			saveButtonProps={{
				disabled: !mergeType,
			}}
			onClose={() => {
				setMergeType( '' );
				closeModal();
			}}
			onSave={async () => {
				const location = selectedCommerces.find( ( invoice ) => invoice.companyLocation )?.companyLocation;
				const client = selectedCommerces.find( ( invoice ) => invoice.client )?.client;
				
				if ( !mergeType ) return;
				let mergedInvoice: Order;
				
				try {
					enqueueSnackbar( t( 'common:merge-inprogress' ), { variant: 'info' } );
					if ( mergeType === 'lineItem' ) {
						const commercesIds = selectedCommerces.map( ( { id } ) => id );
						const selectedCommercesWithIds = keyBy( selectedCommerces, 'id' );
						
						let commercesWithIndex = selectedCommerces.reduce( ( obj,
							invoice ) => ( { ...obj, [ invoice.id ]: 0 } ), {} as Record<string, number> );
						
						const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
							query    : LineItemsRead,
							variables: { options: { limit: 10000, filter: { order: { $in: commercesIds } } } },
						} );
						
						const lineItems = sortBy( lineItemsRead?.items, [ 'order.createdAt' ] );
						
						const commerceLineItems = lineItems?.map( ( lineItem, index ) => {
							const order = selectedCommercesWithIds[ lineItem.order.id ];
							const date = order.type === 'ORDER' ? order.createdAt : order.serviceDate || order.createdAt;
							const desc = `(#${order.number} for ${safeFormatInTimeZone( date || new Date(), 'PP' )})`;
							
							if ( !commercesWithIndex[ lineItem.order.id ] ) {
								commercesWithIndex = { ...commercesWithIndex, [ lineItem.order.id ]: 1 };
							}
							
							return {
								number     : order.metadata?.customNumber || order.externalId || order.number,
								date,
								sequence   : index,
								description: commercesWithIndex[ lineItem.order.id ] === 1 ? `${desc}
									${order.po ? `PO: ${order.po}` : ''}
									${order.notes ? `Note: ${order.notes}` : ''}` : desc,
								lineItem,
							};
						} );
						
						// commerceLineItems = sortBy( commerceLineItems, [ ( l ) => l.lineItem.sequence ], [ ( l ) => l.lineItem.name ], [ ( l ) => l.lineItem.price ] );
						
						const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
							query    : AgreementsRead,
							variables: { options: { limit: 2000, filter: { order: { $in: commercesIds } } } },
						} );
						const agreements = agreementsRead?.items;
						
						const { commerceDuplicate } = await mutateGraphQL<MutationCommerceDuplicateArgs>( {
							mutation : CommerceDuplicate,
							variables: { ids: commercesIds },
						} );
						
						let merchantClient: Client;
						if ( client && location ) {
							merchantClient = await getClientForMerchant( {
								companyLocation: location,
							}, client, staff );
						}
						
						const { commerceWrite } = await mutateGraphQL<MutationCommerceWriteArgs>( {
							mutation : CommerceWrite,
							variables: {
								id          : commerceDuplicate.id,
								customNumber: Boolean( staff.company.metadata?.customNumber ),
								method      : 'Merged',
								input       : {
									gateway        : null,
									client         : merchantClient?.id || null,
									companyLocation: location?.id || null,
									metadata       : {
										...omit( commerceDuplicate.metadata, commercePropertiesToOmit ),
										enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
										signatureLine     : staff.company.metadata?.signatureLine,
										sendReminder      : staff.company.metadata?.dueReminder,
										mergedSignatures  : selectedCommerces?.reduce( ( obj, commerce ) => {
											if ( commerce.metadata?.signature ) {
												obj = {
													...obj,
													[ commerce.number || commerce.externalId ]: commerce.metadata.signature || '',
												};
											}
											return obj;
										}, {} ),
									},
									notes          : null,
									po             : null,
									lineItems      : commerceLineItems?.map( ( item ) => {
										const lineItem = item.lineItem;
										return {
											...pick( lineItem, [ 'name',
											                     'price',
											                     'originalPrice',
											                     'cashDiscount',
											                     'image',
											                     'unit',
											                     'quantity',
											                     'tax',
											                     'metadata',
											                     'externalId' ] ),
											sequence      : item.sequence,
											isRevenue     : false,
											description   : addDescription
												? `${lineItem.description || ''}
												${item.description}`
												: null,
											modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
											prices        : lineItem.prices?.map( ( price ) =>
												pick( price, [ 'name',
												               'isPercent',
												               'value',
												               'quantity',
												               'metadata',
												               'externalId' ] ) ),
											uom           : lineItem.uom?.id || null,
											item          : lineItem.item?.id || null,
											category      : lineItem.category?.id || null,
										};
									} ),
									agreements     : agreements?.map( ( agreement ) => ( {
										...pick( agreement, [
											'title',
											'body',
											'requireSignature',
											'expiration',
										] ),
										company: agreement.company.id,
									} ) ),
									prices         : !isEmpty( commerceDuplicate.prices )
										? commerceDuplicate.prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
											.map( ( { id } ) => ( { id } ) )
										: [],
								},
							},
						} );
						
						mergedInvoice = commerceWrite;
						
						await mutateGraphQL<MutationCommercesWriteArgs>( {
							mutation : CommercesWrite,
							variables: {
								inputs: selectedCommerces.map( ( commerce ) => ( {
									id      : validate( commerce.id ) ? commerce.id : undefined,
									merged  : true,
									metadata: { mergedTo: commerceDuplicate.id },
								} ) ),
							},
						} );
						
					} else {
						selectedCommerces = sortBy( selectedCommerces, [ 'createdAt' ] );
						
						const commerceLineItems = selectedCommerces.map( ( commerce, index ) => {
							const date = commerce.type === 'ORDER'
								? commerce.createdAt
								: commerce.serviceDate || commerce.createdAt;
							const desc = safeFormatInTimeZone( date || new Date(), 'PP' );
							return {
								id         : undefined,
								name       : `${type} #${commerce.externalId || commerce.number}`,
								tax        : 0,
								price      : commerce.grandTotal,
								quantity   : 1,
								unit       : type,
								sequence   : index,
								description: `${desc}
								${commerce.po ? `PO: ${commerce.po}` : ''}
								${commerce.notes ? `Note: ${commerce.notes}` : ''}`,
								prices     : [],
							};
						} );
						
						const { commerceDuplicate } = await mutateGraphQL<MutationCommerceDuplicateArgs>( {
							mutation : CommerceDuplicate,
							variables: { ids: commerces },
						} );
						
						const { commerceWrite } = await mutateGraphQL<MutationCommerceWriteArgs>( {
							mutation : CommerceWrite,
							variables: {
								id          : commerceDuplicate.id,
								customNumber: Boolean( staff.company.metadata?.customNumber ),
								method      : 'Merged',
								input       : {
									metadata : {
										...omit( commerceDuplicate.metadata, commercePropertiesToOmit ),
										enableCashDiscount: staff.company.metadata?.cashDiscount > 0,
										signatureLine     : staff.company.metadata?.signatureLine,
										sendReminder      : staff.company.metadata?.dueReminder,
										mergedSignatures  : selectedCommerces?.reduce( ( obj, commerce ) => {
											if ( commerce.metadata?.signature ) {
												obj = {
													...obj,
													[ commerce.number || commerce.externalId ]: commerce.metadata.signature || '',
												};
											}
											return obj;
										}, {} ),
									},
									prices   : [],
									notes    : null,
									po       : null,
									lineItems: commerceLineItems.map( ( lineItem ) => ( {
										...idPick( lineItem, [
											'name',
											'price',
											'originalPrice',
											'cashDiscount',
											'quantity',
											'tax',
											'unit',
											'description',
											'sequence',
										] ),
										isRevenue: false,
										prices   : undefined,
									} ) ),
								},
							},
						} );
						mergedInvoice = commerceWrite;
						
						await mutateGraphQL<MutationCommercesWriteArgs>( {
							mutation : CommercesWrite,
							variables: {
								inputs: selectedCommerces.map( ( commerce ) => ( {
									id         : validate( commerce.id ) ? commerce.id : undefined,
									merged     : true,
									serviceDate: commerce.serviceDate || commerce.updatedAt,
									metadata   : { mergedTo: commerceDuplicate.id },
								} ) ),
							},
						} );
					}
					
					if ( location?.gateway || mergedInvoice.companyLocation?.gateway ) {
						try {
							await postCloverMeteredBilling( {
								orderId    : mergedInvoice.id,
								gatewayId  : location?.gateway?.id || mergedInvoice.companyLocation?.gateway?.id,
								eventType  : 'HA Merged',
								key        : 'mergeCommerces',
								staffId    : staff?.id,
								clientId   : mergedInvoice.client?.id || null,
								entityCount: selectedCommerces.length || 1,
							} ).catch( () => null );
						} catch {
						}
						
					}
					
					await router.push( `/dashboard/commerce/${commerceType}s/${mergedInvoice.id}/edit` );
					
					clearRows();
					closeModal();
				} catch ( e ) {
					console.log( 'error', e );
				}
			}}>
			<Grid
				container
				spacing={1}
				sx={{
					'.MuiListItemButton-root': {
						p      : 2,
						display: 'block',
						height : { xs: '100%', sm: 250 },
						
					},
				}}>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						disableGutters
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : mergeType === 'order' ? 'primary.main' : 'divider',
						}}
						onClick={() => setMergeType( 'order' )}>
						<Typography variant='h5' mb={2}>{`Merge ${type}s (Recommended)`}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{`${type} ${t( 'common:merge-option-des-one' )} ${commerceType} ${t( 'common:merge-option-des-two' )}
							${t( 'common:merge-option-des-three' )} ${commerceType} ${t( 'common:merge-option-des-four' )} ${commerceType} ${t( 'common:merge-option-des-five' )}
							${t( 'common:merge-option-des-six' )} ${commerceType} ${t( 'common:merge-option-des-seven' )} ${commerceType}s.`}
						</ListItemText>
					</ListItemButton>
				</Grid>
				<Grid item xs={12} sm={6}>
					<ListItemButton
						sx={{
							border      : 4,
							borderRadius: 3,
							borderColor : mergeType === 'lineItem' ? 'primary.main' : 'divider',
						}}
						onClick={() => setMergeType( 'lineItem' )}>
						<Typography variant='h5' mb={2}>{t( 'common:merge-line-items' )}</Typography>
						<ListItemText sx={{ color: 'text.secondary' }}>
							{`${t( 'common:line-merge-des-one' )} ${commerceType} ${t( 'common:line-merge-des-two' )}
							${t( 'common:line-merge-des-three' )} ${commerceType}.
							${t( 'common:line-merge-des-four' )} ${commerceType} ${t( 'common:line-merge-des-five' )}`}
						
						</ListItemText>
						<Tooltip title={t( 'common:line-merge-desription' )}>
							<FormControlLabel
								label={t( 'common:line-merge-des-tooltip' )}
								control={(
									<CustomCheckBox
										checked={addDescription}
										sx={{ ml: 1 }}
										onChange={( e, checked ) => setAddDescription( checked )}
									/>
								)}
							/>
						</Tooltip>
					</ListItemButton>
				</Grid>
			</Grid>
		</ResponsiveModalContainer>
	);
}
