import { Person as PersonIcon } from '@mui/icons-material';
import { Avatar, Box, Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import type { Client } from '../types/schema';
import CustomTooltip from './popups/customTooltip';

interface UserPopoverProps {
	children: ReactNode,
	user?: any,
	client?: Client
}

export default function UserPopover( { children, user, client }: UserPopoverProps ) {
	const { t } = useTranslation();
	const entity = user || client;
	
	if ( !entity ) return null;
	
	const { image, firstName, lastName, email, contact, createdAt }: any = entity;
	
	return (
		<CustomTooltip
			placement='right'
			toolTipChildren={() => (
				<Box sx={{ p: 1, minWidth: 250 }}>
					<Stack>
						<Avatar src={( entity !== 'Invoiss' || firstName !== 'Invoiss' )
							? image
							: `${process.env.NEXT_PUBLIC_SITE_URL}/images/invoissfeathericon-black.png`}
						        sx={{ width: 55, height: 55, mb: 1 }}>
							<PersonIcon/>
						</Avatar>
						{firstName && (
							<Typography variant='h5' color='text.primary'>
								{`${firstName} ${lastName || ''}` || t( 'common:client' )}
							</Typography>
						)}
						{email && firstName !== 'Invoiss' && (
							<Typography color='text.primary'>
								{email}
							</Typography>
						)}
						{contact && (
							<Typography color='text.primary'>
								{contact}
							</Typography>
						)}
						{entity !== 'Invoiss' && firstName !== 'Invoiss' && <Typography color='text.secondary'>
							Joined {user?.company?.name || ''} on {createdAt ? format( createdAt, 'PP' ) : 'N/A'}
						</Typography>}
						{user?.locations && (
							<Box sx={{ pt: 1 }}>
								{user.locations.map( ( location, index ) => (
									<Chip
										key={index}
										size='small'
										label={location.name || location.address.line1}
									/>
								) )}
							</Box>
						)}
					</Stack>
				</Box>
			)}>
			<Box sx={{ '.MuiChip-root': { cursor: 'default' } }}>
				{children}
			</Box>
		</CustomTooltip>
	);
}
