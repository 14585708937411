import { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { InvoiceOrderEstimateEmailModal } from '@/modals/email/invoiceOrderEstimateEmailModal';
import useWriteLog from '@/pages/dashboard/commerce/components/utils';
import DeviceLoadingModal from '@/pages/dashboard/settings/devices/deviceLoadingModal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';

import {
	Edit as EditIcon,
	Email as EmailIcon,
	Payment as PaymentIcon,
	PictureAsPdf as PictureAsPdfIcon,
	Print as PrintIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { isEmpty, toLower, upperFirst } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { stayOpen } from '../../components/tableHelpers';
import PaymentDrawer from '../../payment/paymentDrawer';

export function usePartialInvoiceTableActions() {
	const { showModal } = useModal();
	
	const { staff, user } = useUserInfo();
	const theme = useTheme();
	const { isCloverDevice } = useGetDeviceInfo();
	const timezone = getBrowserTimezone();
	const editable = usePermissions( permissions.invoices.write );
	const { t } = useTranslation();
	const payable = usePermissions( permissions.payments.read || permissions.payments.write );
	
	const logWrite = useWriteLog();
	
	return ( invoice: Order, closeModal?: () => void, noPay?: boolean ) => {
		const disableEditIfPaid = invoice.status === 'PAID' && staff?.disableActions?.commerces?.disableEditPaid;
		
		const dataColor = invoice.metadata?.documentLayout?.color || staff?.company.metadata?.documentLayout?.color;
		const themeBasedLayoutColor = !isEmpty( dataColor?.light ) ? dataColor?.light?.slice( 1 ) : undefined;
		const type = upperFirst( toLower( invoice.type ) );
		
		return [
			editable && {
				name       : t( 'common:edit' ),
				icon       : <EditIcon/>,
				onClick    : () => closeModal?.(),
				details    : disableEditIfPaid ? 'You do not have permission to edit this paid invoice.' : '',
				buttonProps: { disabled: disableEditIfPaid },
				props      : {
					component: PageLinkComponent,
					href     : `/dashboard/commerce/invoices/${invoice.id}/edit`,
				},
			},
			editable
			&& payable
			&& !noPay
			&& ![ 'CANCELLED', 'PAID', 'PENDING', 'ACCOUNT' ].includes( invoice.status )
			&& !invoice.standing
			&& invoice.grandTotal > 0
			&& {
				name   : t( 'common:pay' ),
				icon   : <PaymentIcon/>,
				onClick: () => {
					closeModal?.();
					showModal( PaymentDrawer, {
						variant: 'drawer',
						onClose: ( event, reason ) => stayOpen( event, reason ),
					}, { order: invoice } );
				},
			},
			isCloverDevice && invoice.gateway?.external === 'CLOVER' && ![
				'PAID',
				'ACCOUNT' ].includes( invoice.status ) && {
				name   : t( 'commerce:swipe-card' ),
				icon   : <PaymentIcon/>,
				onClick: async () => {
					return showModal( DeviceLoadingModal, {
							maxWidth: 'sm',
							onClose : ( event, reason ) => stayOpen( event, reason ),
						}, {
							invoice,
							title         : 'Swipe Card',
							bodyTitle     : 'Payment in progress.',
							buttonText    : 'Pay',
							secondaryTitle: `Pay this ${type} by card on Clover`,
						},
					);
					
					await logWrite( {
						company   : invoice.company.id,
						name      : 'UPDATE',
						method    : 'Swiped Card On Clover',
						documentId: invoice.id,
						user      : user?.id,
						table     : 'INVOICE',
					} );
				},
			}, {
				name   : isEmpty( invoice.metadata?.sentDates ) ? t( 'common:email' ) : t( 'common:remail' ),
				icon   : <EmailIcon/>,
				onClick: async () => {
					showModal( InvoiceOrderEstimateEmailModal, {
						onClose: ( event, reason ) => stayOpen( event, reason ),
					}, { id: invoice.id } );
				},
			}, !isCloverDevice && {
				name   : t( 'common:print' ),
				icon   : <PrintIcon/>,
				onClick: async () => {
					window.open( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/print/${invoice.id}/invoice?timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`, '_blank' );
					
					await logWrite( {
						company   : invoice.company.id,
						name      : 'Print',
						method    : `Printed ${type}`,
						documentId: invoice.id,
						user      : user?.id,
						table     : 'INVOICE',
					} );
					
				},
			}, isCloverDevice && invoice.externalId && invoice.gateway?.external === 'CLOVER' && {
				name   : t( 'common:print' ),
				icon   : <PrintIcon/>,
				onClick: async () => {
					closeModal?.();
					if ( window?.Android ) {
						window.Android.onPrintReceiptByOrderId( invoice.externalId );
					}
					await logWrite( {
						company   : invoice.company.id,
						name      : 'Print',
						method    : `Printed ${type} on Clover`,
						documentId: invoice.id,
						user      : user?.id,
						table     : 'ORDER',
					} );
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf' ),
				icon   : <PictureAsPdfIcon/>,
				onClick: async () => {
					window.open( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${invoice.id}/invoice?timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`, '_blank' );
					
					await logWrite( {
						company   : invoice.company.id,
						name      : 'PDF',
						method    : `PDF ${type}`,
						documentId: invoice.id,
						user      : user?.id,
						table     : 'INVOICE',
					} );
					
				},
			}, !isCloverDevice && {
				name   : t( 'common:pdf-no-price' ),
				icon   : <PictureAsPdfIcon/>,
				onClick: async () => {
					window.open( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${invoice.id}/invoice?s=pickup&timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`, '_blank' );
					
					await logWrite( {
						company   : invoice.company.id,
						name      : 'PDF',
						method    : `PDF No Price ${type}`,
						documentId: invoice.id,
						user      : user?.id,
						table     : 'INVOICE',
					} );
					
				},
			}, invoice.type === 'ACCOUNT' && !isCloverDevice && {
				name       : t( 'commerce:detailed-pdf' ),
				buttonProps: { variant: 'outlined' },
				icon       : <PictureAsPdfIcon/>,
				onClick    : async () => {
					
					const state = {
						invoiceId: invoice.id,
					};
					const pdfFilters = encodeURIComponent(
						Buffer.from( JSON.stringify( state ) ).toString( 'base64' ),
					);
					if ( pdfFilters ) {
						window.open(
							`${process.env.NEXT_PUBLIC_SITE_URL}/api/preview/pdf/${invoice.company.id}/invoiceDetailed?s=${pdfFilters}&timezone=${timezone}${themeBasedLayoutColor
								? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
								: ''}&themeMode=${theme.palette.mode}`,
							'_blank',
						);
						
						await logWrite( {
							company   : invoice.company.id,
							name      : 'PDF',
							method    : `PDF Detailed ${type}`,
							documentId: invoice.id,
							user      : user?.id,
							table     : 'INVOICE',
						} );
						
					}
				},
			},
		] as ActionProps[];
	};
}
