import CustomTooltip from '@/components/popups/customTooltip';
import currencyFormat from '@/helpers/currencyFormat';
import type { Payment } from '@/types/schema';
import {
	AttachMoneyRounded as AttachMoneyRoundedIcon,
	CreditCardRounded as CreditCardRoundedIcon,
	HistoryEdu as HistoryEduIcon,
	MonetizationOnRounded as MonetizationOnRoundedIcon,
	MoneyRounded as MoneyRoundedIcon,
} from '@mui/icons-material';
import { Box, Chip, ListItem, ListItemText, Typography } from '@mui/material';
import { format } from 'date-fns';
import { startCase, toLower } from 'lodash-es';
import type { ReactElement } from 'react';

/* eslint-disable react/jsx-key */
export const paymentType: Record<string, [ string, 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', ReactElement ]> = {
	'card'           : [ 'Card', 'primary', <CreditCardRoundedIcon sx={{ pl: '2px' }}/> ],
	'credit card'    : [ 'Card', 'primary', <CreditCardRoundedIcon sx={{ pl: '2px' }}/> ],
	'us_bank_account': [ 'US Bank Account', 'info', <MoneyRoundedIcon sx={{ pl: '2px' }}/> ],
	'bank_transfer'  : [ 'Bank Transfer', 'info', <MoneyRoundedIcon sx={{ pl: '2px' }}/> ],
	'cash'           : [ 'Cash', 'success', <MonetizationOnRoundedIcon sx={{ pl: '2px' }}/> ],
	'Cash'           : [ 'Cash', 'success', <MonetizationOnRoundedIcon sx={{ pl: '2px' }}/> ],
	'invoiss'        : [ 'Invoiss', 'info', <HistoryEduIcon sx={{ pl: '2px' }}/> ],
	'check'          : [ 'Check', 'info', <MoneyRoundedIcon sx={{ pl: '2px' }}/> ],
	'house account'  : [ 'House Account', 'info', <MoneyRoundedIcon sx={{ pl: '2px' }}/> ],
	'paid'           : [ 'Paid', 'success', <MonetizationOnRoundedIcon sx={{ pl: '2px' }}/> ],
	'refund'         : [ 'Refund', 'warning', <MonetizationOnRoundedIcon sx={{ pl: '2px' }}/> ],
	'other'          : [ 'Other', 'info', <AttachMoneyRoundedIcon sx={{ pl: '2px' }}/> ],
};

export function PaymentTypeChip( { payments, name }: { payments: Payment[ ], name: string } ) {
	const total = payments.reduce( ( sum, payment ) => {
		if ( payment.status === 'REFUNDED' || payment.status === 'PARTIALLY_REFUNDED' ) {
			sum -= payment.refundedAmount || 0;
		} else {
			sum += payment.amount;
		}
		return sum;
	}, 0 );
	
	return payments.length ? (
		<CustomTooltip
			sx={{ minWidth: 200 }}
			placement='right'
			toolTipChildren={() => (
				<Box p={.3}>
					<Typography color='text.secondary'>
						Payments on this {name}
					</Typography>
					{payments.map( ( payment ) => {
						const isRefunded = [ 'REFUNDED', 'PARTIALLY_REFUNDED' ]?.includes( payment.status );
						const status = isRefunded ? payment.status : payment.type;
						const amount = isRefunded ? payment.refundedAmount || 0 : payment.amount;
						return (
							<ListItem
								key={payment.id}
								disableGutters
								divider={payments.length > 1}
								sx={{ color: 'text.primary' }}>
								<ListItemText
									primary={startCase( toLower( status ) )}
									primaryTypographyProps={{ color: isRefunded ? 'warning.main' : undefined }}
									secondary={payment?.createdAt ? format( payment.createdAt, 'PPp' ) : ''}
									secondaryTypographyProps={{ fontSize: '11px !important' }}
								/>
								<ListItemText
									primary={currencyFormat( amount )}
									primaryTypographyProps={{ textAlign: 'end' }}
									secondary={payment.tip ? currencyFormat( payment.tip ) : '-'}
									secondaryTypographyProps={{ fontSize: '11px !important', textAlign: 'end' }}
								/>
							</ListItem>
						);
					} )}
					{payments.length > 1 && (
						<ListItem disableGutters sx={{ color: 'text.primary' }}>
							<ListItemText primary='Total'/>
							<Typography>
								{currencyFormat( total )}
							</Typography>
						</ListItem>
					)}
				</Box>
			)}>
			<Chip
				size='small'
				variant='outlined'
				sx={{ border: 'none' }}
				label={
					paymentType[ toLower( payments.slice( -1 )[ 0 ]?.type ) ]?.[ 0 ]
					|| startCase( toLower( payments[ 0 ]?.type?.split( '_' ).join( ' ' ) ) )
					|| paymentType.other[ 0 ]
				}
				color={paymentType[ toLower( payments.slice( -1 )[ 0 ]?.type ) ]?.[ 1 ] || paymentType.other[ 1 ]}
				icon={paymentType[ toLower( payments.slice( -1 )[ 0 ]?.type ) ]?.[ 2 ] || paymentType.other[ 2 ]}
			/>
		</CustomTooltip>
	) : null;
}
