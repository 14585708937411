import { Skeleton, Stack } from '@mui/material';

export default function PreviewDrawerSkeleton( { width }: { width: number } ) {
	return (
		<Stack spacing={2} p={2} width={width}>
			<Skeleton
				animation='wave'
				height={25}
				width='80%'
				sx={{ borderRadius: 2 }}
			/>
			<Stack
				direction='row'
				alignItems='center'
				spacing={1}>
				{[ ...new Array( 3 ) ].map( ( _, index ) => (
					<Skeleton
						key={index}
						animation='wave'
						height={30}
						width={100}
						sx={{ borderRadius: 2 }}
					/>
				) )}
			</Stack>
			<Skeleton
				animation='wave'
				width='100%'
				height={800}
				sx={{ borderRadius: 2, height: '100%', transform: 'none' }}
			/>
		</Stack>
	);
}

