import StyledImage from '@/components/styledImage';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { PaymentTypeChip } from '@/hooks/paymentTypeChip';
import { getDueDateInfo } from '@/modals/repeatDateModal/utils';
import type { Order, Purchase } from '@/types/schema';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { Box, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { isEmpty, toLower } from 'lodash-es';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CommerceStatusChip from '../chips/commerceStatusChip';

export default function MobileRowPanel( { data }: { data: Order & Purchase } ) {
	const { getDueDateText, getDueDateColor } = getDueDateInfo( data );
	const { t } = useTranslation();
	return (
		<Fragment>
			<ListItemText
				primaryTypographyProps={{ component: 'div' }}
				secondaryTypographyProps={{ component: 'div' }}
				sx={{ my: 0, alignSelf: 'start' }}
				primary={(
					<Stack spacing={.5}>
						{data.number && (
							<Typography color='text.secondary' fontWeight={500}>
								{data.metadata?.customNumber || data.number}
							</Typography>
						)}
						{data.client ? (
							<Typography variant='h6'>
								{data.client.name || data.client.contact || data.client.email}
							</Typography>
						) : (
							<Typography color='colors.info.light'>
								{data.type ? t( 'common:no-client' ) : t( 'common:no-vendor' )}
							</Typography>
						)}
						{data.menu && (
							<Typography color='text.secondary'>
								{data.menu.name}
							</Typography>
						)}
						{data.serviceDate ? (
							<Typography color='text.secondary'>
								{safeFormatInTimeZone( data.serviceDate, 'PPp' )}
							</Typography>
						) : (
							<Typography color='text.secondary'>
								{safeFormatInTimeZone( data.updatedAt, 'PPp' )}
							</Typography>
						)}
						{data.staff && (
							<Typography color='text.secondary'>
								{t( 'common:by' )} {data.staff.user?.firstName}
							</Typography>
						)}
					</Stack>
				)}
			/>
			<Box textAlign='right' sx={{ alignSelf: 'end', alignItems: 'self-end', justifyContent: 'end' }}>
				<Stack
					direction='row'
					spacing={1}
					alignItems='right'
					sx={{ alignSelf: 'end', alignItems: 'center', justifyContent: 'end' }}>
					{data?.externalId && (
						<Tooltip title={t( 'commerce:available-on-clover' )}>
							<StyledImage
								alt='clover-pos-image'
								src='/images/clover-icon.png'
								width='10px'
								height='10px'
							/>
						</Tooltip>
					)}
					{!isEmpty( data?.attachments ) && <AttachFileIcon sx={{ fontSize: 12 }}/>}
					{data.status && (
						<CommerceStatusChip cellData={data}/>
					)}
				</Stack>
				<Typography variant='h6' fontWeight='500' my={1}>
					{currencyFormat( Math.abs( +data.grandTotal ) )}
				</Typography>
				{data.payments && (
					<Box>
						<PaymentTypeChip payments={data.payments} name={toLower( data?.type )}/>
					</Box>
				)}
				{!data.payments?.length && (
					<Tooltip
						placement='right'
						title={safeFormatInTimeZone( data.dueDate, 'PP' )}>
						<Typography color={getDueDateColor()}>{getDueDateText()}</Typography>
					</Tooltip>
				)}
			</Box>
		</Fragment>
	);
}
